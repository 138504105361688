const MainTheme = {
  alertErrorBg: '#fceaea',
  alertErrorBorder: '#dc3030',
  alertErrorText: '#420e0e',
  alertInfoBg: '#b3cfe4',
  alertInfoBorder: '#337fb8',
  alertInfoText: '#003053',
  alertSuccessBg: '#ebf9f1',
  alertSuccessBorder: '#38c172',
  alertSuccessText: '#113a22',
  alertWarningBg: '#fefaf0',
  alertWarningBorder: '#f4ca64',
  alertWarningText: '#312814',
  black: '#080808',
  btnBg: '#f18f01',
  btnBgDisabled: '#fbddb3',
  btnBgHover: '#c17201',
  btnGhost: '#f8f7f6',
  btnGhostDisabled: '#44403c',
  btnGhostHover: '#e7e5e4',
  btnLink: '#669fca',
  btnLoader: '#f5b14d',
  btnLoaderBg: '#f8f7f6',
  btnText: '#f8f7f6',
  btnTextDisabled: '#f5b14d',
  editText: '#57534e',
  error100: '#fceaea',
  error200: '#f1acac',
  error300: '#e76e6e',
  error400: '#dc3030',
  error500: '#b02626',
  error600: '#841d1d',
  error700: '#420e0e',
  eyeColour: 'rgba(255, 255, 255, 0.33)',
  eyeColourHover: 'rgba(255, 255, 255, 1)',
  fontBody: 'Open Sans, sans-serif',
  fontColour: 'white',
  fontHeader: 'Poppins, sans-serif',
  fontSizeBase: '1rem',
  fontSizeExtraSmall: '0.75rem',
  fontSizeHeader: '1.5rem',
  fontSizeLarge: '1.25rem',
  fontSizeSmall: '0.875rem',
  formGroupBg: 'rgba(255, 255, 255, 0.075)',
  formGroupBgDisabled: '#d6d3d1',
  formGroupBorder: 'rgba(255, 255, 255, 0.15)',
  formGroupBorderError: '#dc3030',
  formGroupFocusBorder: '#f18f01',
  formGroupFocusGlow: 'rgba(254, 198, 10, 0.5)',
  formGroupInput: 'white',
  formGroupInputActive: '#f18f01',
  formGroupText: '#fec60a',
  formGroupTextError: '#dc3030',
  formHeaderText: 'white',
  grey100: '#f5f5f5',
  grey200: '#e5e5e5',
  grey300: '#d4d4d4',
  grey400: '#a3a3a3',
  grey500: '#737373',
  grey600: '#525252',
  grey700: '#404040',
  grey800: '#262626',
  grey900: '#171717',
  loaderSpin: '#f5b14d',
  loaderSpinBg: 'rgba(0, 0, 0, 0.1) ',
  loaderTextColour: '#ffffff',
  menuBgColour: '#1c1917',
  menuButtonColour: '#f8f7f6',
  menuDivider: '#d6d3d1',
  menuDropdownBg: '#ffffff',
  menuDropdownText: '#1c1917',
  menuDropdownTextActive: '#cb9e08',
  menuDropdownTextHover: '#987706',
  menuHamburgerColor: '#f8f7f6',
  menuHamburgerColorActive: '#f8f7f6',
  menuItemActive: '#fec60a',
  menuItemColour: '#d6d3d1',
  menuItemHover: '#cb9e08',
  menuMobileBg: '#1c1917',
  menuMobileWidth: '250px',
  menuShadow: '5px 5px 10px #e8e8e8, -5px -5px 10px #ffffff',
  modalBg: '#f8f7f6',
  modalCloseBg: '#f8f7f6',
  modalCloseText: '#1c1917',
  phoneInputBg: 'transparent',
  phoneInputBgDisabled: '#e7e5e4',
  phoneInputBorder: 'rgba(0, 0, 0, 0.25)',
  phoneInputListBg: 'white',
  phoneInputListHighlight: '#c17201',
  phoneInputListHighlightText: '#f8f7f6',
  phoneInputListHover: '#f8c780',
  phoneInputListText: '#1c1917',
  phoneInputText: '#1c1917',
  primary100: '#fff4ce',
  primary200: '#ffe89d',
  primary300: '#fedd6c',
  primary400: '#fed13b',
  primary500: '#fec60a',
  primary600: '#cb9e08',
  primary700: '#987706',
  primary800: '#664f04',
  secondary100: '#e6eff6',
  secondary200: '#b3cfe4',
  secondary300: '#669fca',
  secondary400: '#337fb8',
  secondary500: '#005fa6',
  secondary600: '#004c85',
  secondary700: '#004374',
  secondary800: '#003053',
  secondary900: '#001c32',
  stepsColour: '#f5b14d',
  stepsDisabledColour: '#e7e5e4',
  stepsDividerColour: '#e7e5e4',
  stepsTextColour: '#1c1917',
  success100: '#ebf9f1',
  success200: '#afe6c7',
  success300: '#74d49c',
  success400: '#38c172',
  success500: '#2d9a5b',
  success600: '#227444',
  success700: '#113a22',
  tableBodyBg: 'white',
  tableHeaderBg: '#f5b14d',
  tableHeaderText: '#f5b14d',
  warning100: '#fefaf0',
  warning200: '#fbeac1',
  warning300: '#f7da93',
  warning400: '#f4ca64',
  warning500: '#c3a250',
  warning600: '#7a6532',
  warning700: '#312814',
  white: '#f8f7f6',
};

export default MainTheme;
