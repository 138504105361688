import styled from 'styled-components';

export const Wrapper = styled.footer`
  background-color: #000000;
  padding: 2rem 1rem;

  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;

  img {
    height: 64px;
  }

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Company = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  height: 100%;
`;

export const Copyright = styled.div`
  color: ${({ theme }) => theme.grey300};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 0.75rem;
  letter-spacing: 1px;
  margin-top: 0.5rem;
  text-align: center;
`;

export const Privacy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: ${({ theme }) => theme.grey300};
    display: inline-block;
    font-family: ${({ theme }) => theme.fontBody};
    font-size: 0.75rem;
    text-decoration: none;
  }

  a:first-child {
    margin-right: 2rem;
  }

  @media only screen and (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const SocialMediaColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .social-media-link:not(:first-child) {
    margin-left: 2rem;
  }

  @media only screen and (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const SocialMedia = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 100%;
  color: ${({ theme }) => theme.grey900};

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.5rem;

  height: 48px;
  width: 48px;
`;
