import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import MainTheme from 'src/theme';
import Footer from 'src/Components/Footer';
import TransMenu from 'src/Components/MainMenu';

const GlobalStyle = createGlobalStyle`
  html, body {
    background-color: #000000;
    height: 100vh;
    margin: 0;
    padding: 0;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    padding: .8em 0 .8em 1rem;

    @media only screen and (min-width: 1200px) {
      padding: .8em 0;
    }
  }

  .vertical-timeline-element-title {
    font-family: ${MainTheme.fontHeader};
  }

  .vertical-timeline-element {
    font-family: ${MainTheme.fontBody};
  }

  .custom-line::before {
    background-color: ${MainTheme.grey700};
  }
`;

interface Props {
  children: React.ReactNode;
}

function Layout({ children }: Props): JSX.Element {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={MainTheme}>
        <TransMenu />
        <main>{children}</main>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default Layout;
